<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content" v-if="currentStatus === ''">
           <div class="title"><span>{{ $t('searchArea.applyDate') }}</span></div>
           <date-selector :class="'searchbar-content'"
                          @setStartDate="setStartDate"
                          @setEndDate="setEndDate"
                          :startDefault="startDefault"
                          :endDefault="endDefault"
                          :configs="dateConfigs" /><!-- :isOldYn="true" @setOldYn="setOldYn" :oldYn="reqData.oldYn" -->
         </div>
      </div>
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('user.option') }}</span></div>
           <div>
             <select class="mr-5" v-model="selectOption">
              <option value="memId">{{ $t('searchArea.id') }}</option>
              <option value="memNick">{{ $t('searchArea.nick') }}</option>
              <option value="recommenderId">{{ $t('searchArea.highuser') }}</option>
            </select>
            <input type="text" class="mr-5" v-model="searchText" :placeholder="''" />
            <button class="btn-search" type="button" @click="pageSeach">
              <i class="fas fa-search"></i>
            </button>
           </div>
         </div>
      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <div class="tab-wrap">
        <button type="button" class="tab-item" :class="{ active: currentStatus === '' }" @click="changeListType" value="all">{{ $t('searchArea.entire') }}</button>
        <button type="button" class="tab-item" :class="{ active: currentStatus === '0' }" @click="changeListType" value="0">{{ $t('button.outAppply') }}</button>
        <button type="button" class="tab-item" :class="{ active: currentStatus === '2' }" @click="changeListType" value="2">{{ $t('button.waitout') }}</button>
        <button type="button" class="tab-item" :class="{ active: currentStatus === '1' }" @click="changeListType" value="1">{{ $t('button.outappList') }}</button>
        <button type="button" class="tab-item" :class="{ active: currentStatus === '-1' }" @click="changeListType" value="-1">{{ $t('button.outrejList') }}</button>
      </div>
      <div class="table-wrapper">
        <table class="mainTable">
          <colgroup>
            <col style="width:6%">
            <col style="width:7%">
            <col style="width:4%">
            <col style="width:7%">
            <col style="width:6%">
            <col style="width:5%">
            <col style="width:6%">
            <col style="width:5%">
            <col style="width:5%">
            <col style="width:6%">
            <col style="width:10%">
            <col style="width:4%">
            <col style="width:6%">
            <col style="width:9%">
            <col style="width:10%">
            <col style="width:4%">
          </colgroup>
          <table-head :headInfo="headInfo" />
          <tbody v-if="applyList.length !== 0">
            <tr v-for="(item, idx) in applyList" :key="idx">
              <td>
                 <div class="fx fx-col">
                    <button type="button" class="fc-id btn-link" @click="detailOpen('user', item.memId)">{{ item.memId }}</button>
                    <button type="button" class="fc-nick btn-link" @click="detailOpen('user', item.memId)">{{ item.memNick }}</button><!-- ({{computedPartnerLevel(item)}}) -->
                 </div>
              </td>
              <td class="partWrap">
                 <div class="topwrap" v-if="item.topUserList">
                    <select class="select">
                       <template v-for="(partner, index) in item.topUserList">
                          <option class="option">
                             <span class="topbox">[{{computedPartnerLevel(partner)}}] </span>
                             <span :class="item.partnerLevel">{{partner.recommenderId}}</span>
                          </option>
                       </template>
                    </select>
                    <i class="icon">+</i>
                 </div>
              </td>
              <td :class="item.partnerLevel">
                 <span class="box">{{computedPartnerLevel(item)}}</span>
              </td>
              <td>
                <span class="account">
                   <span>
                      <em>{{ item.bankName }}</em>
                      <em class="roboto">{{ item.bankacc }}</em>
                   </span>
                   <em>{{ item.memName }}</em>
                </span>
              </td>
              <td>
                <span class="money-user roboto">{{ numberWithCommas(Math.abs(item.cashAmt)) }}</span>
              </td>
              <td>
                 <div class="fx-center-col gap-5">
                    <span class="roboto">{{item.cashInAmt ? numberWithCommas(Math.abs(item.cashInAmt)): '-' }} {{ $t('common.won') }}</span>
                    <span class="roboto fc-red">{{ item.lastCashInDate }}</span>
                 </div>
              </td>
              <td>
				        <span class="money-apply roboto">{{item.betSumAmt ? numberWithCommas(Math.abs(item.betSumAmt)): '-' }} {{ $t('common.won') }}</span>
              </td>
              <td>
                <span class="roboto">{{ item.bonusPointYn }}</span>
              </td>
              <td>
                <span class="money-apply">
                    <button type="button" v-if="!item.rate" class="btn-innerTable btn-status-change btn-calu" value="" @click="rateCalculate(item, idx)">{{ $t('button.cal') }}</button>
                    <template v-else>
                      <span class="roboto">{{item.rate}}%</span>
                    </template>
                </span>
              </td>
              <td>
                <span class="roboto">{{ numberWithCommas(item.preCashAmt) }}</span>
              </td>
              <td>
                <span class="roboto">{{ item.regDate }}</span>
              </td>
              <td>
                <span v-if="item.cashStatus == '1'" class="bl">{{ $t('button.approval') }}</span>
                <span v-if="item.cashStatus == '-1'" class="rd">{{ $t('button.refu') }}</span>
              </td>
              <td>
                <span class="roboto">{{ numberWithCommas(Math.abs(item.preCashAmt) - Math.abs(item.cashAmt)) }}</span>
              </td>
              <td>
                <div class="status-change-btn-wrap">
                  <button class="btn-innerTable btn-status-change wait" type="button" value="2" :data-cash-idx="item.cashIdx" v-if="item.cashStatus == '0'" @click="setCashStatus(item, '2')">{{ $t('common.wait') }}</button>
                  <button
                    class="btn-innerTable btn-status-change approve"
                    type="button"
                    value="1"
                    :data-list-idx="idx"
                    :data-cash-idx="item.cashIdx"
                    v-if="item.cashStatus == '2'"
                    @click="setCashStatus(item, '1')"
                  >
                  {{ $t('button.approval') }}
                  </button>
                  <button
                    class="btn-innerTable btn-status-change refuse"
                    type="button"
                    value="-1"
                    :data-list-idx="idx"
                    :data-cash-idx="item.cashIdx"
                    v-if="item.cashStatus == '2'"
                    @click="setCashStatus(item, '-1')"
                  >
                  {{ $t('button.refu') }}
                  </button>
                </div>
              </td>
              <td><!-- table-note-wrap -->
                <div class="status-change-btn-wrap">
                  <button class="btn-innerTable btn-status-change wait" type="button" @click="goList(item, 'betGame')">{{ $t('betting.totalBetCount') }}</button>
                  <button class="btn-innerTable btn-status-change wait" type="button" @click="goList(item, 'UserCashChangeHistory')">{{ $t('betting.cashmove') }}</button>
                  <button class="btn-innerTable btn-status-change wait" type="button" @click="goList(item, 'userPointList')">{{ $t('betting.pointList') }}</button>
                  <button class="btn-innerTable btn-status-change wait" type="button" @click="msgSend(item)">{{ $t('button.sendMsg') }}</button>
                </div>
              </td>
              <td><!-- table-note-wrap -->
                <div class="status-change-btn-wrap" v-if="item.cashStatus !== '2'">
                  <button type="button" class="btn-table-note btn-memo" v-if="item.cashStatus !== '0'" :data-list-idx="idx" :value="item.cashStatus" :data-cash-idx="item.cashIdx" @click="setMemo(item)">
                    {{ $t('button.write') }}
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="16">
                <span class="notice-tableDataEmpty">{{ $t('txt.noData') }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="applyList.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />

    <div class="modal-wrapper" v-if="stateMemo">
       <div class="modal-wrap2">
          <div class="sub-title2">
            <h2>{{ $t('table.head.takeNote') }}</h2>
            <h4>{{ getDomain() }}</h4>
            <a class="btn" @click="stateMemo = false"><i class="fa fa-times"></i></a>
          </div>
          <div class="modal-content2">
            <div v-if="this.stateMemo.cashStatus == '2'" class="memoInfo">
               <span>출금자명: {{this.stateMemo.name}}</span>
               <span>출금금액: {{ numberWithCommas(this.stateMemo.cash) }}</span>
            </div>
            <p class="modal-txt m-0" v-if="this.stateMemo.cashStatus == '2'">"{{this.stateMemo && this.stateMemo.changeStatus === '1' ? '승인' : '거절'}} 버튼을 누르셨습니다.<br />계속 진행하시겠습니까?"</p>
            <textarea :placeholder="$t('txt.enterNote')" v-model="currentMemo"></textarea>
            <div class="modal-btns">
               <a @click="setCashMemo">{{ $t('button.save') }}</a>
               <a @click="closeMemo" class="btn">{{ $t('searchArea.cancel') }}</a>
            </div>
          </div>
       </div>
    </div>

  </section>
  <msg-modal v-if="msgOpen" :boardType="'msgList'" :recieveId="msgMemId" :targetType="'member'" @modalClose="modalClose"></msg-modal>
</template>

<script>

import { getDateStr } from '@/libs/utils.js'
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import TableHead from '@/components/main/table/Head.vue'
import { comBank, cashList, cashStatusChange, getRateCalculate } from '@/api/member.js'
import Pagination from '@/components/common/Pagination'
import MsgModal from '@/components/common/msgModal.vue'
import { partnerLevels } from '@/api/partner'
import { PARTNER_LEVEL } from '@/libs/constants'
import Memo from '@/components/common/memo'

export default {
  name: 'loginList',
  components: {
    TableHead,
    DateSelector,
    SearchFilter,
    Pagination,
    MsgModal,
    Memo
  },
  props: {
    status: {
      type: String,
      default: 'all'
    }
  },
  watch: {
    $route: {
      async handler () {
        // this.currentStatus = this.$route.params.status || this.$route.query.status
        // await this.setTableData(1);
      }
    }
  },
  data: function () {
    return {
      part: false,
      stateMemo: false,
      partnerLevel: {},
      tableName: 'Basic',
      msgOpen: false,
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      startDefault: '',
      endDefault: '',
      headInfo: {
        fstColumn: false,
        dataList: ['memIdNick', 'upper', 'level', 'accInfoname', 'outAppplym', 'lastDpsttime', 'betAmt', 'bonuss', 'rollRate', 'befMy', 'applyDate', 'status', 'aftMy', 'statusChange', 'option', 'memo']
      },
      srchFiltersProp: {
        selectOptions: [
          {
            useAll: true,
            opts: [
              {
                title: 'id'
              },
              {
                title: 'nick'
              }
            ]
          }
        ]
      },
      reqData: {
        cashType: 'out',
        cashStatus: this.currentStatus,
        page: 1,
        count_per_list: '30'
      },
      pageInfo: {},
      applyList: [],
      currentStatus: 'all',
      msgMemId: '',
      selectOption: 'memId',
      searchText: '',
      currentMemo: ''
    }
  },
  methods: {
    getDomain () {
      return location.host
    },
    async rateCalculate (item, idx) {
      const params = {
        memId: item.memId
      }

      await getRateCalculate(params).then(res => {
        console.log(res)
        if (res.resultCode === '0') {
          const data = res.data.calculate
          this.applyList[idx] = {
            ...item,
            ...data
          }

          console.log(item)
        } else {
          this.applyList[idx] = {
            ...item,
            cashAmt: 0,
            cashInAmt: 0,
            betSumAmt: 0
          }
        }
      })
    },
    computedPartnerLevel (item) {
      return PARTNER_LEVEL[item.partnerLevel]
    },
    goList (item, type) {
      const routeData = this.$router.resolve({ name: type, query: { id: item.memId } })
      window.open(routeData.href, '_blank')
    },
    msgSend (item) {
      this.msgMemId = item.memId
      this.msgOpen = true
    },
    modalClose () {
      this.msgMemId = ''
      this.msgOpen = false
    },
    setOldYn (data) {
      this.reqData.oldYn = data
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(0, 0, 0)
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(23, 59, 59)
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    pageSeach () {
      this.setTableData(1)
    },
    openWindow () {
      window.open('/user/userDetail', 'userDetail', 'top=0,left=150,scrollbars=no,resizable=no,width=1900,height=885,status=no')
    },
    hideOpen (target) {
      target = !target
    },
    async setOption () {
      this.reqData.memId = ''
      this.reqData.memNick = ''
      this.reqData.recommenderId = ''
      this.reqData[this.selectOption] = this.searchText
    },
    async getCashList (pageNum) {
      if (pageNum) {
        this.reqData.page = pageNum
      }
      this.reqData.cashStatus = this.currentStatus
      await this.setOption()
      const data = this.reqData
      if (data.cashStatus !== '') {
        return await cashList({ cashStatus: data.cashStatus, cashType: data.cashType, page: data.page })
      } else {
        return await cashList(data)
      }
    },
    async changeListType (evt) {
      const target = evt.target
      let chooseType = target.value
      this.currentStatus = chooseType
      if (chooseType === '') {
        chooseType = 'all'
      }
      this.$router.replace({ params: {}, query: { status: chooseType } })
      // await this.setTableData(1);
    },
    async setTableData (pageNum, status) {
      this.emitter.emit('Loading', true)
      if (status) {
        this.currentStatus = status
      }
      const res = await this.getCashList(pageNum)
      const applyList = res.data.list
      const pageInfo = res.data.pageInfo
      console.log(applyList)
      if (pageInfo) {
        this.pageInfo = pageInfo
      }
      this.applyList = this.dataConvertor(applyList)
      this.emitter.emit('Loading', false)
    },
    async setCashStatus (item, changeStatus) {
      const cashStatus = item.cashStatus
      const cashIdx = item.cashIdx
      const memo = item.memo
      const name = item.memName
      const cash = item.cashAmt

      if (cashStatus === '0') {
        await this.updateCashStatus({ cashIdx, cashStatus, memo, name, cash }, changeStatus)
      } else {
        this.stateMemo = {
          cashIdx,
          cashStatus,
          changeStatus,
          name,
          cash
        }
      }
    },
    async updateCashStatus (reqData, changeStatus) {
      this.emitter.emit('Loading', true)
      try {
        console.log(reqData)
        if (changeStatus) {
          reqData.cashStatus = changeStatus
        }
        const res = await cashStatusChange(reqData)
        // console.log(res)
        if (res.resultCode !== '0') {
          alert('처리가 완료되었습니다.')
        }
        await this.setTableData()
      } catch (e) {
        console.log(e)
        alert('출금신청 상태 변경에 실패했습니다. 다시 시도해주세요.')
      }
      this.stateMemo = false
      this.emitter.emit('Loading', false)
    },
    setCashMemo () {
      const reqData = {
        ...this.stateMemo,
        memo: this.currentMemo
      }
      this.updateCashStatus(reqData, this.stateMemo.changeStatus)
      /* if (this.currentMemo) {
        this.updateCashStatus(reqData, this.stateMemo.changeStatus)
      } else {
        alert('메모를 입력해주세요.')
     } */
    },
    setMemo (item) {
      this.currentMemo = item.memo || ''
      this.stateMemo = {
        cashIdx: item.cashIdx,
        cashStatus: item.cashStatus,
        memo: item.memo || ''
      }
    },
    closeMemo () {
      this.currentMemo = ''
      this.stateMemo = false
    },
    dataConvertor (dataList) {
      const _dataList = dataList
      if (!dataList.length || dataList.length === 0) {
        return _dataList
      }
      _dataList.forEach(items => {
        const cashStatus = items.cashStatus
        let statusText = ''
        if (cashStatus === '0') {
          statusText = '신청'
        } else if (cashStatus === '1') {
          statusText = '승인'
        } else if (cashStatus === '2') {
          statusText = '대기'
        } else if (cashStatus === '-1') {
          statusText = '거절'
        }
        items.cashStatusText = statusText
        for (const item in items) {
          if (items[item] !== 0) {
            if (!items[item]) {
              items[item] = '-'
            }
          }
        }
        for (const bank of this.bankList) {
          if (bank.bankCode === items.bank) {
            items.bankName = bank.bankNameKr
          }
        }

        items.topUserList = items.topUserList.reverse()
      })
      return _dataList
    },
    async getBank () {
      const req = {
        siteId: this.siteInfo.siteId
      }
      const res = await comBank(req)
      this.bankList = res.data.list
    },
    async getPartnerLevel () {
      const params = {
        masterCode: 'partner',
        groupCode: 'partner'
      }
      await partnerLevels(params).then(res => {
        const result = res.data
        if (res.resultCode === '0') {
          const levelObj = { NORMAL: '회원' }
          const list = result.partnerLevels
          list.forEach(item => {
            const code = item.code
            if (!levelObj.hasOwnProperty(code)) {
              levelObj[code] = item.codeName
            }
          })

          this.partnerLevel = levelObj
        }
      })
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    await this.getPartnerLevel()
    const currentStatus = this.$route.query.status || this.status
    if (currentStatus === 'all') {
      this.currentStatus = ''

      this.setStartDate()
      this.setEndDate()
    } else {
      this.currentStatus = currentStatus
    }

    this.siteInfo = this.getSiteInfo()
    await this.getBank()
    await this.setTableData(1)
    this.emitter.emit('Loading', false)
  }
}
</script>

<style scoped>
.mainTable >>> th:nth-child(4),
.mainTable >>> th:nth-child(6) {white-space: break-spaces;line-height: 1.5em;}
.table-wrapper,.mainTable,.mainTable td {overflow: inherit;}
.btn-memo {background-color: #fff9f0;height: 20px;font-size: 11px;padding: 0;color: #3d3d3d;border: 1px solid #000;box-sizing: border-box;border-radius: 2px;}
.btn-calu {background-color: #fff9f0;height: 20px;font-size: 11px;padding: 0;width: 50px;color: #3d3d3d;border: 1px solid #000;box-sizing: border-box;margin: 0 auto;}
.bl {color: blue;}
.rd {color: red;}
.account {display: flex;flex-wrap: wrap;align-items: center;justify-content: center;gap: 5px;}
.account > span {display: flex;align-items: center;gap: 5px;}
.account em:nth-child(3) {width: 100%;}
.status-change-btn-wrap {flex-wrap: wrap;gap: 5px;}
.status-change-btn-wrap button {margin: 0;width: 62px;font-size: 10px;}
.partWrap {/*position: relative;*/overflow: inherit;}
.partWrap > span {cursor: pointer;color: #48004e;line-height: 1.5em;}
.partBoxWrap {position: absolute;top: 41px;left: 25px;color: #fff;z-index: 1;display: flex;align-items: center;gap: 10px;background: #48004e;padding: 5px 10px;border-radius: 5px;}
.partBoxWrap > a {display: flex;align-items: center;justify-content: center;color: #fff;cursor: pointer;}

.memoInfo {display: flex;flex-direction: column;align-items: center;gap: 5px;margin-bottom: 15px;}
</style>
